<template>
    <div>
        <v-toolbar
                dense
                tabs
                color="primary darken-2"
                dark
        >
            <v-toolbar-title class="pl-2">Signup</v-toolbar-title>
        </v-toolbar>

        <v-container>
            <v-layout row>
                <v-flex xs12 md8 lg6 offset-md2 offset-lg3>
                    <v-slide-y-transition mode="out-in">
                        <div>
                            <v-card class="blue lighten-4"  >
                                <v-card-text style="" class=" pa-2">
                                    <p class="dark--text adjust-text-size pa-0 ma-0 text-xs-center">
                                        <span> Bravo <b>{{ $store.state.username }}</b> 🌟, your <v-chip style="border: 2px rgb(1, 1, 48) solid!important; font-size: 12px" small color="primary"><b class="pa-0 white--text">SEED</b></v-chip> account has been created. A otp-code has been sent via SMS to your phone number. Plese input it below.</span>
                                    </p>
                                </v-card-text>
                            </v-card>

                            <v-card color="white pa-3 " style="background-color: rgb(255, 255, 255)!important" >
                                <b>Step 2/3: Verify Phone Number</b>
                                <v-layout row>
                                    <v-flex xs12 md10 offset-md1>            
                                        <v-layout row>
                                            <v-flex xs2 sm1>
                                                <i class="teal--text fas fa-lock fa-2x "> </i>                            
                                            </v-flex>
                                            <v-flex xs9 sm10>
                                                <v-text-field 
                                                    class="pt-0 mt-0" ref="otp"
                                                    id="otp" v-model="otp"
                                                    label="Enter verification code(OTP)"
                                                    :rules="[v => !!v || 'Code(OTP) is required']"
                                                    required
                                                    single-line 
                                                    color="teal"
                                                ></v-text-field>
                                            </v-flex>
                                            <v-flex xs1 >
                                                <v-btn @click.stop="$store.commit('activateDialog', true); otp_help = true; getInputFieldHelp()" flat fab small outline color="black" class="my-2" style="height:20px; width: 20px" >
                                                    <i class="fas fa-question"> </i>                            
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>

                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs8 md6 offset-md1>
                                        <a class='mr-1' :href="'https://api.whatsapp.com/send?phone=237678113152/&text=Hello PettCash, i am *'+$store.state.username+'*. I need help with verifying my Phone Number: *'+$store.state.user_info.user_profile.phone_number+'*'" target='_blank'>
                                            <v-btn icon class="elevation-12">
                                                <v-avatar class="white--text green darken-2" size="38">
                                                    <i class='fa-brands fa-whatsapp fa-2x'></i>
                                                </v-avatar>
                                            </v-btn>
                                        </a>            
                                        <small>Contact us on whatsapp</small>
                                    </v-flex>
                                    <v-flex xs4 md6 offset-md1>            
                                        <div class=" text-xs-right">
                                            <v-btn small class="white--text" round color="teal darken-2" @click="continueCreateAccount()" >
                                                <span> Next</span>
                                            </v-btn>
                                        </div>
                                    </v-flex>
                                </v-layout>
                            </v-card>
                        </div>
                    </v-slide-y-transition>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                otp: "",
                otp_help: false,
            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | SignUp"
        },

        methods: {
            getInputFieldHelp(){
                if (this.otp_help){
                    this.otp_help = false
                    this.$store.commit('setDialogTitle', "One Time Password (OTP)")
                    this.$store.commit('setDialogMessage', "For security purposes, you will need to verify your phone number. Inorder to have it secured, we send a 6-digit one time code to that phone number by SMS, please input that code into the field provided.")
                    this.$store.commit('activateDialog', true)
                }
            },

            async continueCreateAccount(){
                if(this.otp === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your otp")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.profession.focus()  // this causes a an auto fucos to the element
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    let formData = new FormData()
                    formData.append('otp', this.otp)
                    await axios
                        .post('/api/v1/home/verify/account/phone_number/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                        .then(response => {
                            console.log(response.data)
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)

                            var navigation = this.$router
                            if (response.data[0].valid){
                                setTimeout(function(){
                                    navigation.push('/savings/campaigns/create/new_campaign')
                                }, 4000)
                            }
                        })
                        .catch(error => {
                            if (error.response){
                                this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }else{
                                this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                                this.$store.commit('setSnackBarColor', "red darken-4")
                                this.$store.commit('activateSnackBar', true)
                            }
                        })
                    this.$store.commit('setIsLoading', false)
                }

            },

        },

    }
</script>
<style >
    @media (max-width: 499px) {
        .adjust-text-size{
            font-size: 12px
        }
    }
    @media (min-width: 499px) {
        .adjust-text-size{
            font-size: 14px
        }
    }
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 14px;
        padding: 5px;
        font-weight: 700;
        color: white;
        background-color: rgb(0, 26, 255);
        display: inline-block;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: rgb(55, 67, 230);
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
    .inputfile:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
        pointer-events: none;
    }
</style>
